export const END_POINTS = {
    BASE_URL: '/api/admin360Intellect-service-express',
    NODE_SERVICE_API_END_POINT: "/api/rosewellness-service-express",
    SF_ERROR_LOGS_ENDPOINT: '/services/data/v51.0/sobjects/',
    SF_QUERY: '/services/data/v52.0/query/?q=',
    SF_AUTHORISE: '/authorise',
    SF_GET_OBJECTS: '/getObjects',
    SF_ACCESS_TOKEN: '/sf/access-token',
    SAVE_ACCESS_TOKEN: '/sf/save-access-token',
    GET_ALL_TABLES: '/get-all-tables',
    GET_TABLE_CALUMNS: '/get-table-columns',
    SAVE_INTEGRATION_MAPPING: '/save-table-fields-mapping',
    DATA_IMPORT: '/data-import',
    GET_INTEGRATION_MAPPING: '/data-import/get-mapping'
}