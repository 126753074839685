import { AfterViewInit, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { UserSecurityService } from 'src/app/core/services/userSecurity/user-security.service';
import { AuthGuard } from 'src/app/helpers/auth.guard';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements AfterViewInit {

  validateUserForm: FormGroup;
  verifyOTPForm: FormGroup;
  userSecurityQuestionsForm: FormGroup;
  setNewPasswordForm: FormGroup;

  isValidUser: boolean = false;
  isInvalidUser: boolean = false;
  isValidOTP: boolean = false;
  isInValidOTP: boolean = false;
  isValidAnswers: boolean = false;
  isInvalidAnswers: boolean = false;
  validAnswer = "Valid Answer";
  showPassword: boolean = false;
  showPassword1: boolean = false;
  isSetUpQueRequired:boolean = false;

  errorMessage = {
    email: " No user found with the given email!",
    setUpQuestions: " Please setup security questions first!",
    otp: " Incorrect OTP!",
    answer:" One or more of your answers are incorrect. Please try again"
  }
  questions = [];

  userDetails: any = {};
  @Output() showLogin = new EventEmitter<boolean>();

  @ViewChild('emailInput', { static: false }) emailInput!: ElementRef;
  @ViewChild('otpInput', { static: false }) otpInput!: ElementRef;
  @ViewChild('answerInput', { static: false }) answerInput!: ElementRef;
  @ViewChild('passwordInput', { static: false }) passwordInput!: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private userSecuritySvc: UserSecurityService,
    private sharedSvc: SharedService
  ) { }

  ngOnInit() {
    this.initForm();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.emailInput.nativeElement.focus();
    });
  }

  getUserSecurityQuestions() {
    this.userSecuritySvc.getUserSecurityQuestions(this.userDetails.UserID).subscribe((res: any) => {
      this.questions = res ? Object.assign([], res) : [];
      if(this.questions.length > 0){
        this.generateOTP();
        this.isValidUser = true;
        setTimeout(() => {
          this.otpInput.nativeElement.focus();
        })
      }
      else{
        this.isSetUpQueRequired = true;
      }
      // console.log("Security Questions ------------->", res);
    });
  }

  initForm() {
    this.validateUserForm = this.formBuilder.group({
      email: ['', Validators.required],
    });

    this.verifyOTPForm = this.formBuilder.group({
      otp: ['', [Validators.required, Validators.pattern(/^\d{6}$/)]],
    });

    this.userSecurityQuestionsForm = this.formBuilder.group({
      answer1: ['', Validators.required],
      answer2: ['', Validators.required],
      answer3: ['', Validators.required],
    });

    this.setNewPasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(8)]],
      reEnterPassword: ['', [Validators.required, Validators.minLength(8)]]
    }, {
      validator: this.passwordMatchValidator
    });
  }

  passwordMatchValidator(group: FormGroup) {
    const password = group.get('newPassword')?.value;
    const confirmPassword = group.get('reEnterPassword')?.value;
    return password === confirmPassword ? null : { mismatch: true };
  }

  checkValidUserEmail() {
    this.isValidUser = this.isInvalidUser = false;
    const email = this.validateUserForm.value.email;

    this.userSecuritySvc.checkUsernameOrEmailExists(email).subscribe((res: any) => {
      // console.log("checkUsername", res);
      if (res.UserID) {
        this.userDetails = Object.assign({}, res);
        this.getUserSecurityQuestions();
      } else {
        this.isInvalidUser = true;
      }
    });
  }

  generateOTP() {
    const email = this.validateUserForm.value.email;

    this.userSecuritySvc.generate2FA(email).subscribe((res: any) => {
      console.log("Generate 2FA", res);
      this.userDetails.otp = res.OTP;
    });
  }

  checkValidOTP() {
    this.isValidOTP = this.isInValidOTP = false;

    const otp = this.verifyOTPForm.value.otp;

    if (otp === this.userDetails.otp) {
      this.isValidOTP = true;
      setTimeout(() => {
        this.answerInput.nativeElement.focus();
      })
    } else {
      this.isInValidOTP = true;
    }

  }

  checkValidAnswers() {
    this.isValidAnswers = this.isInvalidAnswers = false;

    forkJoin([
      this.userSecuritySvc.validateSecurityAnswer(this.userDetails.UserID, this.questions[0].SecurityQuestionID, this.userSecurityQuestionsForm.value.answer1),
      this.userSecuritySvc.validateSecurityAnswer(this.userDetails.UserID, this.questions[1].SecurityQuestionID, this.userSecurityQuestionsForm.value.answer2),
      this.userSecuritySvc.validateSecurityAnswer(this.userDetails.UserID, this.questions[2].SecurityQuestionID, this.userSecurityQuestionsForm.value.answer3),
    ]).subscribe({
      next: ([res1, res2, res3]: any[]) => {
        if (res1?.ValidationStatus === this.validAnswer && res2?.ValidationStatus === this.validAnswer && res3?.ValidationStatus === this.validAnswer) {
          // console.log("All Questions are Valid");
          this.isValidAnswers = true;
          setTimeout(() => {
            this.passwordInput.nativeElement.focus();
          })
        } else {
          this.isInvalidAnswers = true;
        }

        // console.log("Question1 -------------->", res1);
        // console.log("Question2 -------------->", res2);
        // console.log("Question3 -------------->", res3);
      },
      error: err => console.error("Error occurred:", err),
    });
  }


  onSelectionChange(questionNumber: number) {
    this.userSecurityQuestionsForm.get(`answer${questionNumber}`).reset();
    this.disableInput(questionNumber);
  }

  getAvailableQuestions(questionNumber: number) {
    // Get the selected questions
    const selectedQuestions = questionNumber === 1 ? [this.userSecurityQuestionsForm.get('question2')?.value, this.userSecurityQuestionsForm.get('question3')?.value] :
      questionNumber === 2 ? [this.userSecurityQuestionsForm.get('question1')?.value, this.userSecurityQuestionsForm.get('question3')?.value] :
        questionNumber === 3 ? [this.userSecurityQuestionsForm.get('question1')?.value, this.userSecurityQuestionsForm.get('question2')?.value] : [];

    return this.questions?.filter(question =>
      !selectedQuestions.includes(question.SecurityQuestionID)
    ) ?? [];
  }

  disableInput(questionNumber: number) {
    if (!this.userSecurityQuestionsForm.get(`question${questionNumber}`).value) {
      this.userSecurityQuestionsForm.get(`answer${questionNumber}`).disable();
    } else {
      this.userSecurityQuestionsForm.get(`answer${questionNumber}`).enable();
    }
  }

  updateUserPassword() {
    this.userSecuritySvc.updateUserPassword(this.userDetails.UserID, this.setNewPasswordForm.value.newPassword).subscribe((res: any) => {
      // console.log("Password Updated", res);
      this.sharedSvc.openSnackBar('Password updated successfully!', '');
      this.showLogin.emit(true);
    })
  }

  onCancel() {
    this.showLogin.emit(true);
  }
}