<!-- Check User Security Answers -->
<div [hidden]="isValidUser">
    <mat-card>
        <form [formGroup]="validateUserForm" (ngSubmit)="checkValidUserEmail()">
            <div class="row-no-gutters">
                <div class="col-xs-12">
                    <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input #emailInput matInput type="text" formControlName="email" placeholder="Email"
                            (click)="isInvalidUser = false">
                    </mat-form-field>
                </div>

                <div class="col-xs-12" [ngClass]="{'mb-0': !isInvalidUser}">
                    <button color="primary" type="submit" name="submit" mat-raised-button
                        [disabled]="validateUserForm.invalid">Submit</button>

                    <button color="secondary" type="button" name="cancel" mat-raised-button
                        (click)="onCancel()">Cancel</button>
                </div>

                <div class="col-xs-12 mb-0 error-message" *ngIf="isInvalidUser">
                    <mat-icon>warning</mat-icon>
                    {{ errorMessage.email }}
                </div>

                <div class="col-xs-12 mb-0 error-message" *ngIf="isSetUpQueRequired">
                    <mat-icon>warning</mat-icon>
                    {{ errorMessage.setUpQuestions }}
                </div>
            </div>
        </form>
    </mat-card>
</div>

<!-- Verify OTP -->
<div *ngIf="isValidUser && !isValidOTP">
    <mat-card>
        <form [formGroup]="verifyOTPForm" class="intellectportal_form intellectportal_login_form form"
            (ngSubmit)="checkValidOTP()">
            <div class="row-no-gutters">
                <div class="col-xs-12">
                    <mat-form-field>
                        <mat-label>OTP</mat-label>
                        <input #otpInput matInput type="number" formControlName="otp" placeholder="OTP"
                            (click)="isInValidOTP = false">
                    </mat-form-field>
                </div>

                <div class="col-xs-12" [ngClass]="{'mb-0': !isInValidOTP}">
                    <button color="primary" type="submit" name="submit" mat-raised-button
                        [disabled]="verifyOTPForm.invalid">Submit</button>

                    <button color="secondary" type="button" name="cancel" mat-raised-button
                        (click)="onCancel()">Cancel</button>
                </div>

                <div class="col-xs-12 mb-0 error-message" *ngIf="isInValidOTP">
                    <mat-icon>warning</mat-icon>
                    {{ errorMessage.otp }}
                </div>

            </div>
        </form>
    </mat-card>
</div>

<!-- Check User Security Answers -->
<div *ngIf="isValidOTP && !isValidAnswers">
    <mat-card>
        <div class="row-no-gutters">
            <div class="col-xs-12">
                <h2>Enter Your Secret Answers</h2>
            </div>
        </div>
        <form [formGroup]="userSecurityQuestionsForm" (ngSubmit)="checkValidAnswers()">
            <div class="row-no-gutters">
                <div class="col-xs-12">
                    <mat-form-field>
                        <mat-label>{{questions?.[0]?.SecurityQuestion || "Security Question"}}</mat-label>
                        <input #answerInput matInput type="text" formControlName="answer1" placeholder="Your Answer"
                            (click)="isInvalidAnswers = false">
                    </mat-form-field>
                </div>

                <div class="col-xs-12">
                    <mat-form-field>
                        <mat-label>{{ questions?.[1]?.SecurityQuestion || "Security Question" }}</mat-label>
                        <input matInput type="text" formControlName="answer2" placeholder="Your Answer"
                            (click)="isInvalidAnswers = false">
                    </mat-form-field>
                </div>

                <div class="col-xs-12">
                    <mat-form-field>
                        <mat-label>{{questions?.[2]?.SecurityQuestion || 'Security Question'}}</mat-label>
                        <input matInput type="text" formControlName="answer3" placeholder="Your Answer"
                            (click)="isInvalidAnswers = false">
                    </mat-form-field>
                </div>

                <div class="col-xs-12" [ngClass]="{'mb-0': !isInvalidAnswers}">
                    <button color="primary" type="submit" name="submit" mat-raised-button
                        [disabled]="userSecurityQuestionsForm.invalid">Submit</button>

                    <button color="secondary" type="button" name="cancel" mat-raised-button
                        (click)="onCancel()">Cancel</button>
                </div>

                <div class="col-xs-12 mb-0 error-message" *ngIf="isInvalidAnswers">
                    <mat-icon>warning</mat-icon>
                    {{ errorMessage.answer }}
                </div>

            </div>
        </form>
    </mat-card>
</div>


<!-- Set New Password -->
<div [hidden]="!isValidAnswers">
    <mat-card>
        <div class="row-no-gutters">
            <div class="col-xs-12">
                <h2>Password Reset</h2>
            </div>
        </div>
        <form [formGroup]="setNewPasswordForm" (ngSubmit)="updateUserPassword()">
            <div class="row-no-gutters">
                <div class="col-xs-12">
                    <mat-form-field>
                        <mat-label>New Password</mat-label>
                        <input #passwordInput matInput [type]="showPassword ? 'text' : 'password'"
                            formControlName="newPassword" placeholder="New Password">
                        <mat-icon matSuffix (click)="showPassword = !showPassword">{{ showPassword ?
                            'visibility' : 'visibility_off' }}</mat-icon>
                    </mat-form-field>
                </div>

                <div class="col-xs-12">
                    <mat-form-field>
                        <mat-label>Re-enter Password</mat-label>
                        <input matInput [type]="showPassword1 ? 'text' : 'password'" formControlName="reEnterPassword"
                            placeholder="Re-enter Password">
                        <mat-icon class="eye-icon" matSuffix (click)="showPassword1 = !showPassword1">{{ showPassword1 ?
                            'visibility' : 'visibility_off' }}</mat-icon>
                    </mat-form-field>
                </div>

                <div class="col-xs-12 mb-0">
                    <button color="primary" type="submit" name="submit" mat-raised-button
                        [disabled]="setNewPasswordForm.invalid">Save</button>

                    <button color="secondary" type="button" name="cancel" mat-raised-button
                        (click)="onCancel()">Cancel</button>
                </div>
            </div>
        </form>
    </mat-card>
</div>