export interface ISubMenu {
    title: string;
    path: string;
    defaultPage: string;
    pageId: string;
    iconName: string;
    iconSource: string;
}

export class SubMenu {
    private _title: string = '';
    private _path: string = '';
    private _defaultPage: string = '';
    private _pageId: string = '';
    private _iconName: string = '';
    private _iconSource: string = '';

    constructor(options?: ISubMenu) {
        if (options) {
            this.set(options);
        }
    }
    //Leads;Users;Access_Control;Missing_Forms;Transactions;Patients;Unsigned_Encounters;Open_Orders;Dashboard;Appointments;Error_Logs;Subscriptions;Support_Tickets;Patients#All_Patients;Patients#New_Patients
    set<T extends ISubMenu>({ ...options }: T): void {
        this._title = options.title;
        this._path = options.path;
        this._defaultPage = options.defaultPage;
        this._pageId = options.pageId;
        this._iconName = options.iconName;
        this._iconSource = options.iconSource;
    }

    get title() {
        return this._title;
    }

    set title(title: string) {
        this._title = title;
    }

    get path() {
        return this._path;
    }

    set path(path: string) {
        this._path = path;
    }

    get defaultPage() {
        return this._defaultPage;
    }

    set defaultPage(defaultPage: string) {
        this._defaultPage = defaultPage;
    }

    get pageId() {
        return this._pageId;
    }

    set pageId(pageId: string) {
        this._pageId = pageId;
    }

    get iconName() {
        return this._iconName;
    }

    set iconName(iconName: string) {
        this._iconName = iconName;
    }

    get iconSource() {
        return this._iconSource;
    }

    set iconSource(iconSource: string) {
        this._iconSource = iconSource;
    }
}
