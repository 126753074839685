export interface IWebConfig {
    parameterName: string;
    parameterValue: string;
}

export interface IFormattedWebConfig {
    id: number;
    parameterName: string;
    parameterValue: string;
    type: string;
    defaultValue: string;
    displayName: string;
    description: string;
}

export class WebConfig {
    // default values of variables
    private _logo: string = '';
    private _loginSplashImage1: string = '';
    private _loginSplashImage2: string = '';
    private _copyright: string = '';
    private _footer: string = '';
    private _primaryColor: string = '';
    private _primaryTextColor: string = '';
    private _secondaryColor: string = '';
    private _secondaryTextColor: string = '';
    private _backgroundColor: string = '';
    private _leftPanelBackgroundColor: string = '';
    private _hyperlinkColor: string = '';
    private _headerFontColor: string = '';

    constructor(options?: IWebConfig[]) {
        if (options) {
            this.set(options);
        }
    }

    set<T extends IWebConfig[]>(options: T): void {
        options.forEach((option) => this.setVariables(option));
    }

    setVariables(webConfig: IWebConfig) {
        // values received from backend
        // setting individual variables
        const { parameterName, parameterValue } = webConfig;
        this[`_${parameterName}`] = parameterValue;
    }

    set allWebConfig(webConfigs: IWebConfig[]) {
        webConfigs.map((webConfig) => this.setVariables(webConfig));
    }

    get logo() {
        return this._logo;
    }

    get loginSplashImage1() {
        return this._loginSplashImage1;
    }

    get loginSplashImage2() {
        return this._loginSplashImage2;
    }

    get copyright() {
        return this._copyright;
    }

    get footer() {
        return this._footer;
    }

    get primaryColor() {
        return this._primaryColor;
    }

    get secondaryColor() {
        return this._secondaryColor;
    }

    get primaryTextColor() {
        return this._primaryColor;
    }

    get secondaryTextColor() {
        return this._secondaryTextColor;
    }

    get backgroundColor() {
        return this._backgroundColor;
    }

    get leftPanelBackgroundColor() {
        return this._leftPanelBackgroundColor;
    }

    get hyperlinkColor() {
        return this._hyperlinkColor;
    }

    get headerFontColor() {
        return this._headerFontColor;
    }

    // get fontFamily() {
    //     return this._fontFamily;
    // }

    // don't add all the web config... 
    // add only variables are used in CSS file
    getWebConfigObj() {
        return {
            primaryColor: this._primaryColor,
            primaryTextColor: this._primaryTextColor,
            secondaryColor: this._secondaryColor,
            secondaryTextColor: this._secondaryTextColor,
            backgroundColor: this._backgroundColor,
            leftPanelBackgroundColor: this._leftPanelBackgroundColor,
            hyperlinkColor: this._hyperlinkColor,
            headerFontColor: this._headerFontColor,
            // fontFamily: this._fontFamily,
        };
    }
}
