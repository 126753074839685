import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { delay, filter } from 'rxjs/operators';
import { Menu } from 'src/app/shared/model/menu.model';
import { ISession, Session } from 'src/app/shared/model/session.model';
import { User } from 'src/app/shared/model/user.model';
import { CryptoService } from 'src/app/shared/services/crytoservice/crypto-service.service';
import { SessionService } from 'src/app/shared/services/session/session.service';
import { LoggedinUserService } from 'src/app/shared/services/loggedin-user/loggedin-user.service';
import { MenuSubmenuService } from 'src/app/shared/services/menu-submenu/menu-submenu.service';
import { PORTAL_TYPE_ROUTES } from '../../constants/sidebar.constants';
import { SharedService } from 'src/app/shared/services/shared.service';

@UntilDestroy()
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  menuList: Menu[] = [];
  activeMenuIndex = 0;
  expandedMenu = [this.activeMenuIndex];

  isDesk: boolean = true;
  isExpanded: boolean = true;
  route = this.location.path();
  routerPath = '';

  userData: User;
  logo = '';

  constructor(
    private observer: BreakpointObserver,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private location: Location,
    private loggedInUserSvc: LoggedinUserService,
    private sessionSvc: SessionService,
    private menuSvc: MenuSubmenuService,
    private sharedSvc: SharedService,
  ) {}

  ngOnInit() {
    const routeParams = this.activeRoute.snapshot.queryParams.routeParams
      ? JSON.parse(
          CryptoService.decryptUsingAES256(
            this.activeRoute.snapshot.queryParams.routeParams
          )
        )
      : null;
    console.log(routeParams);
    if (routeParams) {
      const { user } = routeParams;
      this.loggedInUserSvc.user = new User(user);
      this.userData = this.loggedInUserSvc.user;

      this.sessionSvc.getNewSessionTokens().subscribe((res: ISession) => {
        this.sessionSvc.session = new Session(res);
      });
    }

    // if user data is not availble
    if (!this.loggedInUserSvc.isUserDataAvailable()) {
      // if local storage data is not available then logout user
      if (!localStorage.getItem('user') || !localStorage.getItem('session')) {
        this.logout();
      } else {
        // get the data from local storage and set the data in user object
        const userData = JSON.parse(
          CryptoService.decryptUsingAES256(localStorage.getItem('user'))
        );
        const sessionData = JSON.parse(
          CryptoService.decryptUsingAES256(localStorage.getItem('session'))
        );
        this.loggedInUserSvc.user = new User(userData);
        this.sessionSvc.session = new Session(sessionData);
        this.userData = this.loggedInUserSvc.user;
        this.getTabsData();
      }
    } else {
      this.userData = this.loggedInUserSvc.user;
      this.getTabsData();
    }
  }

  ngAfterViewInit() {
    this.router.events.subscribe((val) => {
      if (this.location.path() != '') {
        this.route = this.location.path();
      } else {
        this.route = 'Home';
      }
    });

    this.observer
      .observe(['(max-width: 992px)'])
      .pipe(delay(1), untilDestroyed(this))
      .subscribe((res) => {
        if (res.matches) {
          this.sidenav.mode = 'over';
          this.isExpanded = true;
          this.isDesk = false;
          this.sidenav.close();
        } else {
          this.sidenav.mode = 'side';
          this.isExpanded = true;
          this.isDesk = true;
          this.sidenav.open();
        }
      });

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e) => e instanceof NavigationEnd)
      )
      .subscribe(() => {
        if (this.sidenav.mode === 'over') {
          this.sidenav.close();
        }
      });
  }

  getTabsData() {
    this.logo = this.loggedInUserSvc.webConfig.logo;
    console.log('getting menu data');
    if (this.menuSvc.getSidebar().length) {
      this.menuList = this.menuSvc.getSidebar();
      console.log("menuList",this.menuList);
      this.setActiveMenuByRoute();
      this.routerPath = this.menuSvc.routePath;
    } else {
      this.menuSvc.getPortalSidebar().subscribe((res: Menu[]) => {
        this.menuList = res;
        console.log(res);
        console.log("menuList",this.menuList);
        this.setActiveMenuByRoute();
        this.routerPath = this.menuSvc.routePath;
      });
    }
  }

  setActiveMenuByRoute() {
    if (this.route.includes('?')) {
      this.route = this.route.split('?')[0];
    }

    this.menuList.forEach((menu, i) => {
      if (menu.expandable) {
        let submenuPaths = menu.subMenus.map((submenu) => submenu.path);

        if (submenuPaths.includes(this.route)) {
          this.subMenuClick(i, menu);
        }
      } else if (menu.path == this.route) {
        this.subMenuClick(i, menu);
      }
    });
  }

  subMenuClick(currentMenuIndex: number, menu: Menu) {
    this.menuSvc.selectedMenu = menu;
    this.activeMenuIndex = currentMenuIndex;
    this.expandedMenu = [this.activeMenuIndex];
  }

  menuClick(currentMenuIndex) {
    if (currentMenuIndex === this.activeMenuIndex) {
      return;
    } else {
      if (this.expandedMenu.includes(currentMenuIndex)) {
        let index = this.expandedMenu.indexOf(currentMenuIndex);
        this.expandedMenu.splice(index, 1);
      } else {
        this.expandedMenu = [this.activeMenuIndex, currentMenuIndex];
      }
    }
  }

  refreshComponent() {
    let url = this.router.url;
    if (url.includes('?')) {
      url = url.split('?')[0];
    }

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`/${url}`]);
    });
  }

  toggleSidebar() {
    this.sidenav.toggle();
  }

  toggleIsExpanded() {
    this.isExpanded = !this.isExpanded;
    this.sidenav.close();

    setTimeout(() => {
      this.sidenav.open();
    }, 250);
  }

  logout() {
    this.loggedInUserSvc.logout();
  }

  navigateToConfig() {
    this.sharedSvc.openNewTab(`/${PORTAL_TYPE_ROUTES.ADMIN_DASHBOARD}`);
  }
}
